import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Bannerfrom from "../components/bannerfrom";


function Banner(props) {
    return (

        <>
            <div className="services_banner">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} lg={7} xl={7} xxl={7} className="my-auto">
                            <div className="banenr_content">
                                <div className="example" data-text="Our Blogs">
                                    <h1>Our Blogs</h1>
                                </div>
                                <div
                                    className="banner_content_txt listing_flex"><p>We share our expertise in software
                                    development services, including web, mobile,
                                    design,
                                    and DevOps, with various platforms.</p></div>

                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto ms-auto">
                            <Bannerfrom title={`Book a Free <span>Consultation</span>`}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Banner;